import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from "pinia";
import { merge } from 'webpack-merge';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import '@/assets/css/style.less'
import '@/assets/icon/iconfont.css'
import { selectLoadMore } from './directive';


const app = createApp(App)
const locale = merge({}, zhCn, {
  el: {
    datepicker: {
      // weeks: {
      //   fri: "周五",
      //   mon: "周一",
      //   sat: "周六",
      //   sun: "周日",
      //   thu: "周四",
      //   tue: "周二",
      //   wed: "周三",
      // }
    }
  }
})
app.use(ElementPlus, {
  locale: locale,
});

app.directive('selectLoadMore', selectLoadMore)
const pinia = createPinia()
app.use(ElementPlus)
app.use(pinia)
app.use(router).mount('#app')
