import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [

  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      // {
      //   path: 'videoLib',
      //   name: 'videoLib',
      //   component: () => import('../views/videoLib/index.vue')
      // },
      // {
      //   path: 'videoCate',
      //   name: 'videoCate',
      //   component: () => import('../views/videoCate/index.vue')
      // },
      // {
      //   path: 'uploadVideo',
      //   name: 'uploadVideo',
      //   component: () => import('../views/uploadVideo/index.vue')
      // }
    ]
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  next()
})

export default router
