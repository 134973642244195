const config = {
  rqbUrl: '',

}
export default () => ({
  QrCodeImg: `https://yushukeji.oss-cn-shenzhen.aliyuncs.com/jdb_contact_us.jpg?t=${Date.now()}`,
  copyRight: '北京指数进化科技有限公司  |	京ICP备19026259号-3	igengzhi.com',
  tip: '数据通过公开渠道获取，京店宝进行统计分析',
  list: [
    {
      title: '活动资源',
      children: [

        {
          title: '提升搜索排名',
          target: '_blank',
          link: `${config.rqbUrl}newReleaseIndex/jd/popular-rank`
        }

      ]
    },
    {
      title: '行业资讯',
      children: [
        {
          title: '搜索书生文章',
          target: '_self',
          link: '/#/information/searchScholars'
        }
      ]
    }

  ]
});
